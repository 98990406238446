(function () {
    angular.module('MonstaFTP').factory('uploadUIFactory', uploadUIFactory);

    uploadUIFactory.$inject = ['uiOperationFactory', 'uploadFactory', '$filter', '$rootScope', '$translate'];

    function uploadUIFactory(uiOperationFactory, uploadFactory, $filter, $rootScope, $translate) {
        var sizeFilter = $filter('file_size'),
            NAME_INDEX = 0,
            REMOTE_PATH_INDEX = 1,
            FILE_INDEX = 2,
            SIZE_INDEX = 3,
            IS_ARCHIVE_INDEX = 4;

        function showErrorModal(message) {
            $rootScope.$broadcast('modal-error:show', message, function () {
                
            });
        }
        
        function showTooLargeFilesError(message, tooLargeFiles) {

            for (var fileIndex = 0; fileIndex < tooLargeFiles.length; ++fileIndex) {
                var fileData = tooLargeFiles[fileIndex];
                message += "<br>&nbsp;&nbsp;&nbsp;&nbsp;" + fileData[0] + " (" + sizeFilter(fileData[1]) + ")";
            }

            showErrorModal(message);
        }

        function broadcastExtractMessage(factory, $rootScope, message) {
            $rootScope.$broadcast("modal-choice:show", "EXTRACT_AFTER_UPLOAD_TITLE", message, [
                ["UPLOAD_STANDARD_ACTION", function() {
                    factory.uploadStandardCallback()
                }],
                ["UPLOAD_EXTRACT_ACTION", function () {
                    factory.uploadAndExtractCallback()
                }]
            ]);
        }

        return {
            tooLargeFiles: [],
            filesToQueue: [],
            fileQueueIndex: 0,
            treeTotalSize: 0,
            treeProcessed: 0,
            traverseFinished: false,
            uploadStandardCallback: function () {
                this.filesToQueue[this.fileQueueIndex][IS_ARCHIVE_INDEX] = false;
                this.processUploadQueue();
            },
            uploadAndExtractCallback: function () {
                this.filesToQueue[this.fileQueueIndex][IS_ARCHIVE_INDEX] = true;
                this.processUploadQueue();
            },
            promptForExtract: function (fileName) {
                var _this = this;
                $translate("EXTRACT_AFTER_UPLOAD_MESSAGE", {
                    file_name: fileName,
                    file_type: extractFileExtension(fileName)
                }).then(function (translatedMessage) {
                    broadcastExtractMessage(_this, $rootScope, translatedMessage);
                }, function () {
                    broadcastExtractMessage(_this, $rootScope, "Extract " + fileName + " after uploading?");
                });
            },
            processUploadQueue: function () {
                if(this.fileQueueIndex >= this.filesToQueue.length) {
                    this.checkTooLargeFiles();
                    this.performUploads();
                    return;
                }

                var fileInfo = this.filesToQueue[this.fileQueueIndex];

                if(fileInfo[IS_ARCHIVE_INDEX] == null && extractFileExtension(fileInfo[NAME_INDEX]) == "zip")
                    this.promptForExtract(fileInfo[NAME_INDEX]);
                else {
                    ++this.fileQueueIndex;
                    this.processUploadQueue();
                }
            },
            performUploads: function() {
                for(var i = 0; i < this.filesToQueue.length; ++i) {
                    var fileInfo = this.filesToQueue[i];
                    uploadFactory.addUpload(fileInfo[NAME_INDEX], fileInfo[REMOTE_PATH_INDEX], fileInfo[FILE_INDEX],
                        fileInfo[SIZE_INDEX], fileInfo[IS_ARCHIVE_INDEX]);
                }

                this.filesToQueue = [];
                this.fileQueueIndex = 0;
                this.tooLargeFiles = [];
            },
            checkTooLargeFiles: function () {
                if (this.tooLargeFiles.length != 0) {
                    var factory = this;
                    $translate('UPLOAD_FILES_TOO_LARGE_MESSAGE', {item_count: this.tooLargeFiles.length,
                        maximum_size: sizeFilter(MAX_UPLOAD_BYTES)}).then(function (translatedMessage) {
                        showTooLargeFilesError(translatedMessage, factory.tooLargeFiles);
                    }, function (message) {
                        showTooLargeFilesError(message, factory.tooLargeFiles);
                    });
                }
            },
            doUploadAdd: function (file, relativeFilePath, isArchive) {
                var remotePath = uiOperationFactory.joinNameToCurrentDirectory(relativeFilePath);

                if (file.size > MAX_UPLOAD_BYTES)
                    this.tooLargeFiles.push([relativeFilePath, file.size]);
                else
                    this.filesToQueue.push([file.name, remotePath, file, file.size, isArchive]);
            },
            traverseFileTree: function (item, path, isArchive) {
                path = path || "";
                var _this = this;
                if (item.isFile) {
                    ++_this.treeTotalSize;
                    item.file(function (file) {
                        ++_this.treeProcessed;
                        var relativeFilePath = pathJoin(path, item.name);
                        _this.doUploadAdd.call(_this, file, relativeFilePath, isArchive);
                        if(_this.traverseFinished && _this.treeProcessed == _this.treeTotalSize) {
                            _this.processUploadQueue();
                        }
                    });
                } else if (item.isDirectory) {
                    var dirReader = item.createReader();
                    dirReader.readEntries(function (entries) {
                        for (var entryIndex = 0; entryIndex < entries.length; ++entryIndex) {
                            _this.traverseFileTree.call(_this, entries[entryIndex], pathJoin(path, item.name),
                                isArchive);
                        }
                    });
                }
            },
            handleItemsBasedUpload: function (items, isArchive) {
                if(!EXTRACT_UPLOAD)
                    isArchive = false;

                this.tooLargeFiles = [];
                this.filesToQueue = [];
                this.fileQueueIndex = 0;
                this.treeTotalSize = 0;
                this.treeProcessed = 0;
                this.traverseFinished = false;
                for (var itemIndex = 0; itemIndex < items.length; ++itemIndex) {
                    var item = items[itemIndex].webkitGetAsEntry();
                    if (item)
                        this.traverseFileTree(item, null, isArchive);
                }
                this.fileQueueIndex = 0;
                this.traverseFinished = true;
            },
            handleFilesBasedUpload: function (files, isArchive) {
                if(!EXTRACT_UPLOAD)
                    isArchive = false;

                this.tooLargeFiles = [];
                this.filesToQueue = [];
                this.fileQueueIndex = 0;
                var checkedFilesCount = 0, _this = this;

                var handleFileCheckFinished = function () {
                    if (checkedFilesCount != files.length)
                        return;

                    for (fileIndex = 0; fileIndex < files.length; ++fileIndex) {
                        file = files[fileIndex];
                        var relativeFilePath = file.webkitRelativePath ? file.webkitRelativePath : file.name;
                        _this.doUploadAdd.call(_this, file, relativeFilePath, isArchive);
                    }
                    _this.processUploadQueue();
                };

                if (window.FileReader == undefined) {
                    // can't check if it's file or folder so just try to upload and hope for the best
                    checkedFilesCount = files.length;
                    handleFileCheckFinished();
                    return;
                }

                var loadSuccess = function() {
                    ++checkedFilesCount;
                    handleFileCheckFinished();
                };

                for (var fileIndex = 0; fileIndex < files.length; ++fileIndex) {
                    var file = files[fileIndex];

                    var reader = new FileReader();

                    reader.onerror = function () {
                        $translate('FOLDER_UPLOAD_CHROME_ONLY_MESSAGE').then(showErrorModal, showErrorModal);
                    };

                    reader.onload = loadSuccess;

                    if (file.size <= MAX_UPLOAD_BYTES) {
                        // we won't be able to upload them anyway so don't bother reading
                        try {
                            reader.readAsBinaryString(file);
                        } catch (e) {

                        }
                    } else
                        loadSuccess();
                }
            }
        };
    }
}());