(function () {
    angular.module('MonstaFTP').controller('LoginPanelController', LoginPanelController);

    LoginPanelController.$inject = ['$scope', 'connectionFactory', 'authenticationFactory', '$rootScope', 'jQuery',
        'licenseFactory', '$translate', 'configurationFactory', 'urlLoginFactory'];

    function LoginPanelController($scope, connectionFactory, authenticationFactory, $rootScope, jQuery,
                                  licenseFactory, $translate, configurationFactory, urlLoginFactory) {
        var modalLoginId = "#modal-login", vm = this, readURL = true;

        $scope.connectionErrorMessage = null;
        $scope.storedAuthenticationErrorMessage = null;
        $scope.defaults = g_ConnectionDefaults;
        $scope.metaConfiguration = {
            rememberLogin: false,
            masterPassword: null,
            savedProfileIndex: null,
            enteredProfileName: null
        };

        $scope.hasServerSavedAuthentication = false;
        $scope.savedAuthentication = null;
        $scope.licenseFactory = licenseFactory;
        $scope.metaConfiguration.saveAuthentication = true; // TODO: this is hardcoded until we have a checkbox for this
        $scope.systemConfiguration = {};
        vm.connectionRestrictions = {};
        vm.applicationSettings = {};

        vm.buildDefaultConfiguration = buildDefaultConfiguration;
        vm.hide = hide;
        vm.show = show;
        vm.handleError = handleError;
        vm.setupInitialDirectory = setupInitialDirectory;
        vm.writeAuthenticationToServer = writeAuthenticationToServer;
        vm.saveCurrentAuthentication = saveCurrentAuthentication;
        vm.removeProfile = removeProfile;
        vm.initiateAuthenticationSave = initiateAuthenticationSave;
        vm.handleAuthenticationSuccess = handleAuthenticationSuccess;
        vm.handleAuthenticationFailure = handleAuthenticationFailure;
        vm.transferConfigToAuthFactory = transferConfigToAuthFactory;
        vm.successCallback = successCallback;
        vm.handleTestConfiguration = handleTestConfiguration;
        vm.testConfiguration = testConfiguration;
        vm.transferConfigFromAuthFactory = transferConfigFromAuthFactory;
        vm.initWithStoredAuth = initWithStoredAuth;
        vm.updateHasServerSavedAuth = updateHasServerSavedAuth;
        vm.addDefaultsToConfig = addDefaultsToConfig;
        vm.initWithDefaultAuth = initWithDefaultAuth;
        vm.init = init;
        vm.handleCreateAuthSuccess = handleCreateAuthSuccess;
        vm.handleLoadSavedAuthSuccess = handleLoadSavedAuthSuccess;
        vm.handleAuthFileFailure = handleAuthFileFailure;
        vm.performCreateAuthFile = performCreateAuthFile;
        vm.initiateLoadOfAuthFile = initiateLoadOfAuthFile;
        vm.loadProfileAtIndex = loadProfileAtIndex;
        vm.loadNewProfile = loadNewProfile;
        vm.configurationSettable = configurationSettable;
        vm.applyRestrictionsToConfiguration = applyRestrictionsToConfiguration;
        vm.shouldShowProUpgrade = shouldShowProUpgrade;
        vm.shouldShowProfiles = shouldShowProfiles;
        vm.showLoginLink = showLoginLink;
        vm.initWithURLConfig = initWithURLConfig;
        vm.profileIsSelected = profileIsSelected;
        vm.getProfileName = getProfileName;
        vm.getDefaultProfileName = getDefaultProfileName;

        jQuery(document).on('shown.bs.tab', '#login-tab-links a', function (e) {
            $scope.connectionType = $(e.target).attr('data-type');
            $scope.$apply(function(){
                $scope.handleProfileChange();
            });

        });

        $scope.connect = function () {
            if ($scope.connectionType == 'sftp' && $scope.systemConfiguration.sftpAvailable === false)
                return;

            vm.testConfiguration("form");
        };

        $scope.handleLoginKeyPress = function ($event) {
            if ($event.which == 13)
                $scope.connect();
        };

        $scope.$on('logout', function () {
            readURL = false;
            vm.init();
        });

        $scope.$on('modal-login:show', function () {
            vm.show();
        });

        function setStoredAuthenticationErrorMessage(errorMessage) {
            $scope.storedAuthenticationErrorMessage = errorMessage;
        }

        $scope.handleAuthGo = function () {
            if (isEmpty($scope.metaConfiguration.masterPassword)) {
                $translate('PROFILE_SET_PASSWORD_ERROR').then(setStoredAuthenticationErrorMessage,
                    setStoredAuthenticationErrorMessage);
                return;
            }

            $scope.storedAuthenticationErrorMessage = '';

            if ($scope.hasServerSavedAuthentication)
                vm.initiateLoadOfAuthFile();
            else
                vm.performCreateAuthFile();
        };

        $scope.masterPasswordKeypress = function ($event) {
            if ($event.which == 13)
                $scope.handleAuthGo();
        };

        $scope.handleProfileChange = function () {
            var selectValue = $scope.metaConfiguration.savedProfileIndex;
            if (selectValue == 'new') {
                vm.loadNewProfile();
                return;
            }

            var profileIndex = parseInt(selectValue);
            if (!isNaN(profileIndex))
                vm.loadProfileAtIndex(profileIndex);
        };

        function showRemoveProfileConfirm(message) {
            $rootScope.$broadcast('modal-confirm:show', message, vm.removeProfile);
        }

        $scope.initiateProfileDelete = function () {
            $translate('PROFILE_DELETE_CONFIRM_MESSAGE').then(showRemoveProfileConfirm, showRemoveProfileConfirm)
        };

        $scope.shouldHideDeleteButton = function () {
            if (!shouldShowProfiles())
                return true;

            return isNaN(parseInt($scope.metaConfiguration.savedProfileIndex));
        };

        $scope.$on('license-loaded', function () {
            vm.init();
        });

        configurationFactory.getSystemConfiguration().then(function (systemConfiguration) {
            $scope.systemConfiguration = systemConfiguration;
            vm.applicationSettings = systemConfiguration.applicationSettings;
            vm.connectionRestrictions = systemConfiguration.applicationSettings.connectionRestrictions;
            licenseFactory.getLicense();
        }, function (response) {
            licenseFactory.getLicense();
        });

        function buildDefaultConfiguration() {
            connectionTypeAllowed();

            var availableConnectionTypes = [
                ['ftp', 'FTP'],
                ['sftp', 'SFTP/SCP']
            ];

            if (DEBUG)
                availableConnectionTypes.push(['mock', 'Mock']);

            $scope.connectionTypes = [];

            for (var ctIndex = 0; ctIndex < availableConnectionTypes.length; ++ctIndex) {
                if (connectionTypeAllowed(availableConnectionTypes[ctIndex][0]))
                    $scope.connectionTypes.push(availableConnectionTypes[ctIndex]);
            }

            $scope.configuration = {};
            if ($scope.connectionTypes.length)
                $scope.connectionType = $scope.connectionTypes[0][0];

            for (ctIndex = 0; ctIndex < $scope.connectionTypes.length; ++ctIndex)
                $scope.configuration[$scope.connectionTypes[ctIndex][0]] = {};
        }

        function hide() {
            jQuery(modalLoginId).modal('hide');
        }

        function show() {
            vm.updateHasServerSavedAuth();
            jQuery(modalLoginId).modal('show');
        }

        function displayTranslatedError(errorMessage, mode) {
            if (mode == 'connection-display')
                $scope.connectionErrorMessage = errorMessage;
            else if (mode == 'saved-profile-display')
                $scope.storedAuthenticationErrorMessage = errorMessage;
            else
                $rootScope.$broadcast('modal-error:show', errorMessage);
        }

        function handleError(response, action, mode) {
            var errorMessage = parseErrorResponse(response, action);

            $translate(errorMessage, {action: action}).then(function (translatedMessage) {
                displayTranslatedError(translatedMessage, mode);
            }, function () {
                displayTranslatedError(errorMessage, mode);
            });
        }

        function setupInitialDirectory(resumeType) {
            var configuration = $scope.configuration, connType = $scope.connectionType; // for shorter refs :)
            if (resumeType == "resume") {
                var authInitDir = authenticationFactory.initialDirectory;
                if (isEmpty(authInitDir))
                    authInitDir = "/";
                configuration[connType].initialDirectory = authInitDir;
            } else {
                if (isEmpty(configuration[connType].initialDirectory))
                    configuration[connType].initialDirectory = "/";

                authenticationFactory.initialDirectory = configuration[connType].initialDirectory;
            }
        }

        function writeAuthenticationToServer() {
            connectionFactory.writeSavedAuth($scope.metaConfiguration.masterPassword, $scope.savedAuthentication)
                .then(function (response) {
                    if (responseIsUnsuccessful(response))
                        vm.handleError(response, 'saving profile');
                }, function (response) {
                    vm.handleError(response, 'saving profile');
                });
        }

        function saveCurrentAuthentication() {
            if ($scope.savedAuthentication == null || typeof $scope.savedAuthentication != 'object')
                $scope.savedAuthentication = {};

            if ($scope.savedAuthentication[$scope.connectionType] == undefined)
                $scope.savedAuthentication[$scope.connectionType] = [];

            var profileToSave = angular.copy($scope.configuration[$scope.connectionType]);

            profileToSave.name = $scope.metaConfiguration.enteredProfileName;

            var profileArray = $scope.savedAuthentication[$scope.connectionType];

            if ($scope.metaConfiguration.savedProfileIndex == 'new')
                profileArray.push(profileToSave);
            else
                profileArray[$scope.metaConfiguration.savedProfileIndex] = profileToSave;

            vm.writeAuthenticationToServer();
        }

        function removeProfile() {
            $scope.savedAuthentication[$scope.connectionType].splice($scope.metaConfiguration.savedProfileIndex, 1);

            $scope.metaConfiguration.savedProfileIndex = Math.min($scope.metaConfiguration.savedProfileIndex,
                $scope.savedAuthentication[$scope.connectionType].length - 1);

            vm.writeAuthenticationToServer();
        }

        function initiateAuthenticationSave() {
            if (isEmpty($scope.metaConfiguration.masterPassword) || !$scope.metaConfiguration.saveAuthentication
                || !$scope.hasServerSavedAuthentication)
                return;

            vm.saveCurrentAuthentication();
        }

        function handleAuthenticationSuccess() {
            authenticationFactory.postLogin();
            vm.initiateAuthenticationSave();
            vm.hide();
            $rootScope.$broadcast('login');
        }

        function handleAuthenticationFailure(resumeType, response) {
            if (resumeType == "resume" || resumeType == "url") {
                if (resumeType == "url") {
                    $scope.configuration[$scope.connectionType].password = null;
                    authenticationFactory.configuration[$scope.connectionType].password = null;
                }
                vm.show();
            } else
                vm.handleError(response, 'authentication', 'connection-display');
        }

        function transferConfigToAuthFactory() {
            var config = angular.copy($scope.configuration);
            vm.applyRestrictionsToConfiguration(config);
            authenticationFactory.configuration = config;
            authenticationFactory.connectionType = $scope.connectionType;
            authenticationFactory.rememberLogin = $scope.metaConfiguration.rememberLogin;
            authenticationFactory.hasServerSavedAuthentication = $scope.hasServerSavedAuthentication;
        }

        function successCallback(resumeType, response) {
            if (responseIsUnsuccessful(response))
                vm.handleAuthenticationFailure(resumeType, response);
            else
                vm.handleAuthenticationSuccess();
        }

        function handleTestConfiguration(resumeType) {
            connectionFactory.testConnectAndAuthenticate().then(function (response) {
                vm.successCallback(resumeType, response);
            }, function (response) {
                vm.handleAuthenticationFailure(resumeType, response);
            });
        }

        function testConfiguration(resumeType) {
            vm.setupInitialDirectory(resumeType);
            vm.transferConfigToAuthFactory();
            vm.handleTestConfiguration(resumeType);
        }

        function transferConfigFromAuthFactory() {
            $scope.connectionType = authenticationFactory.connectionType || $scope.connectionTypes[0][0];
            $scope.metaConfiguration.rememberLogin = authenticationFactory.rememberLogin;
            $scope.hasServerSavedAuthentication = authenticationFactory.hasServerSavedAuthentication;
        }

        function initWithStoredAuth() {
            $scope.configuration = angular.copy(authenticationFactory.configuration);

            if (authenticationFactory.isAuthenticated)
                vm.testConfiguration("resume");
            else
                vm.show();
        }

        function initWithURLConfig(urlConfig) {
            var configuration = {};

            configuration[urlConfig.type] = urlConfig.configuration;

            authenticationFactory.configuration = angular.copy(configuration);

            $scope.configuration = angular.copy(configuration);
            $scope.connectionType = urlConfig.type;
            vm.testConfiguration("url");
        }

        function updateHasServerSavedAuth() {
            connectionFactory.checkSavedAuthExists().then(function (response) {
                $scope.hasServerSavedAuthentication = (response.data.data === true);
            }, function () {
                $scope.hasServerSavedAuthentication = false;
            });
        }

        function addDefaultsToConfig() {
            $scope.configuration.ftp.passive = true;
        }

        function initWithDefaultAuth() {
            vm.addDefaultsToConfig();
            vm.show();
        }

        function init() {
            vm.buildDefaultConfiguration();
            authenticationFactory.loadSettings();
            vm.transferConfigFromAuthFactory();

            var configFromURL = null;

            if (readURL && licenseFactory.isLicensed())
                configFromURL = urlLoginFactory.getConfigFromCurrentURL();

            if (configFromURL != null)
                vm.initWithURLConfig(configFromURL);
            else if (authenticationFactory.hasStoredAuthenticationDetails())
                vm.initWithStoredAuth();
            else
                vm.initWithDefaultAuth();
        }

        function handleCreateAuthSuccess() {
            $scope.masterPasswordValid = true;
            $scope.hasServerSavedAuthentication = true;
        }

        function handleLoadSavedAuthSuccess(response) {
            $scope.savedAuthentication = response.data.data;
            $scope.masterPasswordValid = true;
        }

        function handleAuthFileFailure(response) {
            $scope.masterPasswordValid = false;
            vm.handleError(response, 'reading/writing the settings file', 'saved-profile-display');
        }

        function performCreateAuthFile() {
            var defaultAuth = {};

            for (var tIndex = 0; tIndex < $scope.connectionTypes.length; ++tIndex) {
                defaultAuth[$scope.connectionTypes[tIndex][0]] = [];
            }

            connectionFactory.writeSavedAuth($scope.metaConfiguration.masterPassword, defaultAuth).then(
                function (response) {
                    if (responseIsUnsuccessful(response))
                        vm.handleAuthFileFailure(response);
                    else
                        vm.handleCreateAuthSuccess(response);
                }, function (response) {
                    vm.handleAuthFileFailure(response);
                });
        }

        function initiateLoadOfAuthFile() {
            connectionFactory.readSavedAuth($scope.metaConfiguration.masterPassword).then(function (response) {
                if (responseIsUnsuccessful(response))
                    vm.handleAuthFileFailure(response);
                else
                    vm.handleLoadSavedAuthSuccess(response);
            }, function (response) {
                vm.handleAuthFileFailure(response);
            });
        }

        function loadProfileAtIndex(profileIndex) {
            $scope.configuration[$scope.connectionType] = angular.copy(
                $scope.savedAuthentication[$scope.connectionType][profileIndex]);

            $scope.metaConfiguration.enteredProfileName = $scope.configuration[$scope.connectionType].name;
        }

        function loadNewProfile() {
            $scope.configuration[$scope.connectionType] = {};
            $scope.metaConfiguration.enteredProfileName = null;
            vm.addDefaultsToConfig();
        }

        function configurationSettable(connectionType, configurationKey) {
            if (vm.connectionRestrictions == null || typeof (vm.connectionRestrictions) != "object")
                return true;

            if (!vm.connectionRestrictions.hasOwnProperty(connectionType))
                return true;

            return !vm.connectionRestrictions[connectionType].hasOwnProperty(configurationKey);
        }

        function applyRestrictionsToConfiguration(configuration) {
            if (vm.connectionRestrictions == null || typeof (vm.connectionRestrictions) != "object")
                return;

            for (var connectionType in vm.connectionRestrictions) {
                if (!vm.connectionRestrictions.hasOwnProperty(connectionType))
                    continue;

                if (!configuration.hasOwnProperty(connectionType))
                    continue;

                var typeConnectionSettings = vm.connectionRestrictions[connectionType];
                if (typeConnectionSettings == null || typeof (vm.connectionRestrictions) != "object")
                    continue;

                for (var settingName in typeConnectionSettings) {
                    if (!typeConnectionSettings.hasOwnProperty(settingName))
                        continue;

                    configuration[connectionType][settingName] = null;
                }
            }
        }

        function connectionTypeAllowed(connectionType) {
            // this function is designed to be very forgiving
            if (vm.connectionRestrictions == null || typeof (vm.connectionRestrictions) != "object")
                return true;

            if (Object.prototype.toString.call(vm.connectionRestrictions.types) != '[object Array]')
                return true;

            if (vm.connectionRestrictions.types.length == 0)
                return true;

            return vm.connectionRestrictions.types.indexOf(connectionType) != -1;
        }

        function shouldShowProUpgrade() {
            if (vm.applicationSettings.hideProUpgradeMessages === true)
                return false;

            return !licenseFactory.isLicensed();
        }

        function shouldShowProfiles() {
            if (vm.applicationSettings.disableMasterLogin === true)
                return false;

            return licenseFactory.isLicensed();
        }

        function showLoginLink() {
            $rootScope.$broadcast("modal-login-link:show", $scope.connectionType,
                $scope.configuration[$scope.connectionType]);
            vm.hide();
        }

        function profileIsSelected() {
            return $scope.metaConfiguration.savedProfileIndex !== ''
                && $scope.metaConfiguration.savedProfileIndex !== null;
        }

        function getProfileName(profile, useDefault) {
            if (!useDefault && profile.name)
                return profile.name;

            if(profile.host == null || (profile.username == null && profile.remoteUsername == null))
                return '';

            return (profile.host || 'host') + " / " + (profile.username || profile.remoteUsername || 'username');
        }

        function getDefaultProfileName() {
            if($scope == null || $scope.configuration == null || $scope.connectionType == null)
                return '';

            return getProfileName($scope.configuration[$scope.connectionType], true);
        }
    }
}());