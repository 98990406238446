(function () {
    angular.module('MonstaFTP').controller('DragDropController', DragDropController);

    DragDropController.$inject = ['uploadUIFactory', 'jQuery'];

    function DragDropController(uploadUIFactory, jQuery) {
        'use strict';
        var overDrop = false, vm = this, $html = jQuery('html');

        vm.handleDropEvent = handleDropEvent;

        $html.ready(function () {
            var $fileDrop = jQuery("#file-xfer-drop");
            $html.on('dragenter', function () {
                var $files = jQuery('#files');

                $fileDrop.css({
                    top: $files.position().top,
                    height: $files.height(),
                    display: "table"
                });

                $fileDrop.show();
            });

            $html.on('dragend', function () {
                if (overDrop)
                    return;

                $fileDrop.hide();
            });

            $fileDrop.ready(function () {
                $fileDrop.on({
                    dragenter: function (e) {
                        overDrop = true;
                        e.stopPropagation();
                        e.preventDefault();
                    },
                    dragleave: function (e) {
                        if (!jQuery.contains($fileDrop, jQuery(e.target)))
                            overDrop = false;
                        e.stopPropagation();
                        e.preventDefault();
                        $fileDrop.hide();
                    },
                    dragover: function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                    },
                    drop: function (e) {
                        overDrop = false;
                        e.stopPropagation();
                        e.preventDefault();

                        var browserEvent = e.originalEvent;
                        if (!browserEvent.dataTransfer || !browserEvent.dataTransfer.files)
                            return false;

                        if (!containsFiles(browserEvent))
                            return false;

                        vm.handleDropEvent(browserEvent);
                        $fileDrop.hide();
                    },
                    dragend: function (e) {
                        e.stopPropagation();
                        e.preventDefault();
                        $fileDrop.hide();
                    }
                });
            });
        });

        function containsFiles(browserEvent) {
            if (browserEvent.dataTransfer.types) {
                for (var i = 0; i < browserEvent.dataTransfer.types.length; i++) {
                    if (browserEvent.dataTransfer.types[i] == "Files") {
                        return true;
                    }
                }

                return false;
            }
        }

        function handleDropEvent(event) {
            debugger;

            event.preventDefault();

            var items = event.dataTransfer.items;

            if (items != undefined)
                uploadUIFactory.handleItemsBasedUpload(items, null);
            else
                uploadUIFactory.handleFilesBasedUpload(event.dataTransfer.files, null);
        }
    }
}());